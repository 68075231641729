/**
 * 兔小巢企业版版本
 */
export enum ENTERPRISE_VERSION {
  /**  公开版  */
  PUBLIC = 1,
  /**   私有版  */
  PRIVATE = 2
}

/**
 * 兔小巢企业版搜索类型
 */
export enum SEARCH_SCENE {
  /**  仅产品 */
  PRODUCT = 1,
  /**  产品和帖子 */
  BOTH = 2,
}

/**
 * 企业版个人中心页面类型
 */
export enum PROFILEPAGE_HASH_TYPE {
  ACTIVITY = 'activity',
  PRODUCTS = 'products',
}


/**
 * 产品类型
 */
export enum OPERATE_SETTING_TYPE {
  /** 产品侧*/
  PRODUCT = 1,
  /** 后台管理*/
  DASHBOARD = 2,
  /** 企业版*/
  ENTERPRISE = 3,
}
