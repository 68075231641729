
type FormatTime = (time: number, format?: string) => string;

const formatTime: FormatTime = (time, format = 'yyyy年MM月dd日 hh:mm') => {
  let res = format;
  const date = new Date(time * 1000);

  const year = `0${date.getFullYear()}`;
  const month = `0${date.getMonth() + 1}`;
  const day = `0${date.getDate()}`;
  const hour = `0${date.getHours()}`;
  const minute = `0${date.getMinutes()}`;
  const second = `0${date.getSeconds()}`;



  if (res.includes('y')) {
    const yearMatchArr = res.match(/y+/);
    res = Array.isArray(yearMatchArr) ? res.replace(/y+/, year.slice(-(yearMatchArr[0].length))) : res;
  }

  if (res.includes('M')) {
    const monthMatchArr = res.match(/M+/);
    res = Array.isArray(monthMatchArr) ? res.replace(/M+/, month.slice(-(monthMatchArr[0].length))) : res;
  }
  if (res.includes('d')) {
    const dayMatchArr = res.match(/d+/);
    res = Array.isArray(dayMatchArr) ? res.replace(/d+/, day.slice(-(dayMatchArr[0].length))) : res;
  }
  if (res.includes('h')) {
    const hourMatchArr = res.match(/h+/);
    res = Array.isArray(hourMatchArr) ? res.replace(/h+/, hour.slice(-(hourMatchArr[0].length))) : res;
  }
  if (res.includes('m')) {
    const minMatchArr = res.match(/m+/);
    res = Array.isArray(minMatchArr) ? res.replace(/m+/, minute.slice(-(minMatchArr[0].length))) : res;
  }
  if (res.includes('s')) {
    const secMatchArr = res.match(/s+/);
    res = Array.isArray(secMatchArr) ? res.replace(/s+/, second.slice(-(secMatchArr[0].length))) : res;
  }
  return res;
};

export default formatTime;

